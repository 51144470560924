import React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
const Result = () => {
  return (
    <div className="inner-body">
      <Container fluid>
        <Row className="inner">
          <div className="inner-head-outer">
            <div className="inner-head">
              <Col>
                <h2>Result</h2>
                <Breadcrumb>
                  <Breadcrumb.Item href="../">
                    <i className="fa fa-home"></i> Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Result</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </div>
          </div>
        </Row>
      </Container>
      <div className="mib relative">
        <div className="leftline"></div>

        <div className="bottomline"></div>
        <Container>
          <Row>
            <Col></Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Result;
