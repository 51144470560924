import React from "react";
import { Container, Row, Col, Breadcrumb, Button } from "react-bootstrap";
import "./About.css";
const About = () => {
  return (
    <div className="inner-body">
      <Container fluid>
        <Row className="inner">
          <div className="inner-head-outer">
            <div className="inner-head">
              <Col>
                <h2>About Us</h2>
                <Breadcrumb>
                  <Breadcrumb.Item href="../">
                    <i className="fa fa-home"></i> Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>About Us</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </div>
          </div>
        </Row>
      </Container>
      <div className="mib relative">
        <div className="leftline"></div>

        <div className="bottomline"></div>
        <Container fluid>
          <Row>
            <Col>
              <p>
                <strong>Madhusudan Public School (MPS)</strong>&nbsp; has been
                started from 1<sup>st</sup> April 2009 to cater quality English
                education in order to meet the needs of the fast changing 21
                <sup>st</sup> century world.
              </p>
              <p>
                <strong>
                  The school is affiliated to CBSE, New Delhi. The Affiliation
                  No. is 3430268
                </strong>
                &nbsp; and &nbsp;<strong>School No. is 58037.</strong>&nbsp;
              </p>
              <p>
                <strong>Madhusudan Public School</strong>&nbsp; popularly known
                as &nbsp;<strong>MPS</strong>&nbsp; which has come a long way in
                nurturing young minds since its inception. Its impressive jouney
                started in 2009 with the ambience for a ground breaking
                education system. The school provides various facilities like
                &nbsp;
                <strong>
                  VAST PLAYGROUND, DIGITAL CLASS, COMPUTER LAB, SCIENCE LAB
                </strong>
                &nbsp; and other separrate &nbsp;<strong>LABS</strong>&nbsp; for
                various subjects, spacious and well staked &nbsp;
                <strong>LIBRARY</strong>&nbsp;. School follows 100 percent
                child-centered approach with emphasis on Continuous &amp;
                Comprehensive Evaluation system implemented by C.B.S.E.
                Participation in the co-scholastic activities has range from
                &nbsp;
                <strong>YOGA, KARATE, ART &amp; CARFT, MUSIC, DANCE</strong>
                &nbsp; etc. are an integral part of the learning system.
              </p>
              <p>
                The school is a co-educational English medium and strictly
                follows &nbsp;
                <strong>CCE guidelines provided by CBSE, New Delhi</strong>
                &nbsp;. Our School holds a motto - &nbsp;
                <strong>" MOTIVATE PERSONAL STRENGTH "</strong>&nbsp; to provide
                the students all sorts of opportunities for achieving all-round
                and harmonious development.
              </p>
              <p>
                <strong>MPS</strong>&nbsp; is situated at Asantalia 4 km away
                from &nbsp;<strong>Railway Station Chakradharpur</strong>&nbsp;
                in west direction linked with &nbsp;
                <strong>Chakradharpur - Ranchi Main Road NH-75 (E)</strong>
                &nbsp;. It is nearly 5 minutes drive to reach &nbsp;
                <strong>MPS</strong>&nbsp; which is nearby &nbsp;
                <strong>
                  SDO Office, Porahat, Chakradharpur, W. Singhbhum, Jharkhand
                </strong>
                &nbsp; .
              </p>
            </Col>
          </Row>
        </Container>
        <div className="">
          <Container fluid>
            <Row>
              <Col>
                <h4>Vision &amp; Mission</h4>
                <p>
                  We believe that education is not only the information but also
                  the life building, character making, assimilation of ideas,
                  which helps in the personality developement of a child.
                </p>
                <ul>
                  <li>
                    Our focus to education is in accordance with the changing
                    needs of time.
                  </li>
                  <li>
                    We focus on the all round development of the student rather
                    than to give only academic excellence.
                  </li>
                  <li>
                    The school takes up different programmes which inculcate a
                    sense of discipline.
                  </li>
                  <li>
                    Adaptability for simple living and high thinking, respect
                    for our age old culture and heritage.
                  </li>
                  <li>
                    The school would like its student to become dynamic and
                    constructive citizen of the country and good human being.
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bg-orange">
          <Container fluid>
            <Row>
              <Col>
                <h4>School Information</h4>
                <p className="text-center">
                  Mandetory Disclosure guided by AICTE, New Delhi
                </p>
                <div align="center">
                  <Button href="/SchoolInformation">School Information</Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="">
          <Container fluid>
            <Row>
              <Col>
                <h4>Our Logo</h4>
                <p>
                  The school holds the motto &nbsp;
                  <strong>“Motivate Personal Strength”</strong>&nbsp;. MPS is
                  epitomizing all that you have ever dreamt for your little one.
                  The school cared out of its fertile innovative way of thinking
                  laced with its outstanding foresight heralds the dawn of a
                  new, beginning of academic organization. The foremost mission
                  of the school is depicted in the logo and we aim to impart
                  training in life-skills deftly combining it with academic
                  nurturing in a cultural ambience. Here at MPS the students are
                  being groomed to have an overall development of their
                  personalities.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        
      </div>
      <div className="mib relative">
        <div className="leftline"></div>

        <div className="bottomline"></div>
       
        <div className="">
          <Container fluid>
            <Row>
              <Col>
                <h4>Chairman's Message</h4>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <div className="mpsimage">
                  <img
                    src="https://www.mpsckp.com/images/cm.jpg"
                    alt="chairman"
                  />
                  <div className="mpsimagedetails">
                    <h5>S. S. Mahto</h5>
                    <h6>Chairman</h6>
                  </div>
                </div>
              </Col>
              <Col md={9}>
                <p>
                  It is my pleasure to state that the school is being founded by
                  high vision of Late Madhusudan Mahto, a renowned Social worker
                  of his time. Madhusudan Mahto High School started in 1992 with
                  an aim to spread the light of knowledge for development of
                  pupils. Presently the school is running under the name of MSM
                  high school offers the courses of studies with both Hindi
                  &amp; English Medium. Our achievement can be seen in Hindi
                  Medium with excellent academic results in Board Examination
                  which was 100% first class in 2008.
                </p>
                <p>
                  In order to meet the present demands of the Parents for
                  quality education in English. It is decided to open a school
                  named Madhusudan Public School (MPS) a complete English Medium
                  School under CBSE Board.
                </p>
                <p>
                  I assure you to give same result and performance among the
                  students in MPS in forthcoming years, for which a constant and
                  humble support from parent's side is needed.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4>Director's Message</h4>
              </Col>
            </Row>
            <Row>
              <Col md={9}>
                <p>
                  First of all I would like to extend a hearty welcome to all
                  the parents who have shown an interest in MPS. Madhusudan
                  Public School (MPS) has been one of my most cherished dreams
                  which is now on the threshold of its completion in terms of
                  education. At MPS, we enable children to excel their hidden
                  potential by exposing them towards diverse opportunities. We
                  teach them to be confident, creative and independent. We
                  believe that education is the tool which alone can inculcate
                  national and cultural values.
                </p>
                <p>
                  We have started digital class in which we use innovative
                  teaching-learning aids that makes learning simple and
                  interesting for students. We believe that education mainly
                  begins at home where parents play an active role by developing
                  the willingness among the children to study and learn. So
                  parents should understand the priority of their children and
                  their dream.
                </p>
                <p>
                  We have been providing ample opportunities to all our students
                  and children are made to derive the best benefits for their
                  overall personality development. Our education aims at
                  development of 3H&apos;s - Hand, Head and Heart. I believe
                  that the healthy learning environment in MPS makes the school
                  just the right place to ensure joy and smile on student&apos;s
                  face to light up the nation.
                </p>
              </Col>
              <Col md={3}>
                <div className="mpsimage">
                  <img
                    src="https://www.mpsckp.com/images/dm.jpg"
                    alt="chairman"
                  />
                  <div className="mpsimagedetails">
                    <h5>B. K. Hindwar</h5>
                    <h6>Director</h6>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <h4>Principal's Message</h4>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <div className="mpsimage">
                  <img
                    src="https://www.mpsckp.com/images/pm.jpg"
                    alt="principal"
                  />
                  <div className="mpsimagedetails">
                    <h5>K. Nagaraju</h5>
                    <h6>Principal</h6>
                  </div>
                </div>
              </Col>
              <Col md={9}>
                <blockquote>
                  “By education, I mean an all- round drawing out of the best in
                  the child and man body, mind and spirit”- Mahatma Gandhi.
                </blockquote>
                <p>
                  Education aims at making children capable of becoming
                  responsible, productive and useful members of society.
                  Knowledge skills and attitudes are built through learning
                  experiences and opportunities created for learner in school.
                  It is in the classroom that learners can analyze and evaluate
                  their experience, learn to doubt, to question, to investigate
                  and to think independently.
                </p>
                <p>
                  It has been our foremost priority to create such circumstance
                  among the student where they can make optimum use of their
                  talent to enhance their skills.
                </p>
                <p>
                  The chairman of the school has provided a constant guidance
                  and support and at the same time the director of the school
                  guided by this innovative and creative ideas.
                </p>
                <p>
                  Last but not the least I would like to thank to all teachers,
                  students and parents from the bottom of my heart for their
                  kind co-operation and support.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default About;
