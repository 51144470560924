import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import Home from "./Home";
import About from "./About";
import Academic from "./Academic";
import Tc from "./Tc";
import Admission from "./Admission";
import Facility from "./Facility";
import Faculty from "./Faculty";
import Result from "./Result";
import Gallery from "./Gallery";
import Vacancy from "./Vacancy";
import Contact from "./Contact";
import SchoolInformation from "./SchoolInformation";
import StudentArena from "./StudentArena";
import GetSingleNotice from "./GetSingleNotice";
import School from "./Gallery/School";
import RepublicDay from "./Gallery/RepublicDay";
import PrizeDistribution from "./Gallery/PrizeDistribution";
import mpslogo from "../images/mps.png";
import logo from "../images/mps_logo.png";
import { Container, Row, Col } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

// import { createBrowserHistory } from "history";

// const customHistory = createBrowserHistory();

let home = "/home";
const Menu = () => {
  return (
    <Router>
      <Container fluid>
        <Row>
          <Col md={4} sm={8} xs={8} lg={4} xl={4}>
            <Row>
              <Col md={3} sm={3} xl={3} xs={4} lg={3}>
                <img src={mpslogo} alt="logo" className="logoinbox img-fluid" />
              </Col>
              <Col md={9} sm={9} xl={9} xs={8} lg={9}>
                <Navbar.Brand as={Link} to={home}>
                  <img src={logo} alt="logo" className="kmps img-fluid" />
                </Navbar.Brand>
              </Col>
            </Row>
          </Col>
          <Col md={8} sm={4} xs={4} lg={8} xl={8}>
            <div className="header-info d-none d-sm-none d-md-block hidden-xs">
              <ul>
                <li>
                  <a href="tel:9955475737">
                    <i className="fa fa-phone-square"></i>+91 9955475737
                  </a>
                </li>
                <li>
                  <a
                    href="https://wa.me/7033474827"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-whatsapp"></i>+91 7033474827
                  </a>
                </li>
                <li>
                  <a href="mailto:mpsckp@gmail.com">
                    <i className="fa fa-envelope-o"></i>mpsckp@gmail.com
                  </a>
                </li>

                <li>
                  <a
                    href="http://www.mpsckp.com/online_application.php"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Admission Open
                  </a>
                </li>
              </ul>
            </div>
            <Navbar expand="lg">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link as={Link} to={"/home"}>
                    <i className="fa fa-home"></i> Home
                  </Nav.Link>
                  <Nav.Link as={Link} to={"/about"}>
                    About Us
                  </Nav.Link>
                  <Nav.Link as={Link} to={"/academic"}>
                    Academic
                  </Nav.Link>
                  <Nav.Link as={Link} to={"/admission"}>
                    Admission
                  </Nav.Link>
                  <Nav.Link as={Link} to={"/facility"}>
                    Facility
                  </Nav.Link>
                  <Nav.Link as={Link} to={"/faculty"}>
                    Faculty
                  </Nav.Link>
                  <Nav.Link as={Link} to={"/result"}>
                    Result
                  </Nav.Link>
                  <Nav.Link as={Link} to={"/gallery"}>
                    Gallery
                  </Nav.Link>
                  <Nav.Link as={Link} to={"/vacancy"}>
                    Join Us
                  </Nav.Link>
                  <Nav.Link as={Link} to={"/contact"}>
                    Contact Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Col>
        </Row>
      </Container>

      <div>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/academic">
            <Academic />
          </Route>
          <Route path="/tc">
            <Tc />
          </Route>
          <Route path="/admission">
            <Admission />
          </Route>
          <Route path="/facility">
            <Facility />
          </Route>
          <Route path="/faculty">
            <Faculty />
          </Route>
          <Route path="/result">
            <Result />
          </Route>
          <Route path="/gallery">
            <Gallery />
          </Route>
          <Route path="/vacancy">
            <Vacancy />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/SchoolInformation">
            <SchoolInformation />
          </Route>
          <Route path="/StudentArena">
            <StudentArena />
          </Route>
          <Route path="/School">
            <School />
          </Route>
          <Route path="/RepublicDay">
            <RepublicDay />
          </Route>
          <Route path="/PrizeDistribution">
            <PrizeDistribution />
          </Route>
          <Route path="/GetSingleNotice/:id" component={GetSingleNotice} />
        </Switch>
      </div>
    </Router>
  );
};

export default Menu;
