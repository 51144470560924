import React from "react";
import axios from "axios";
import Moment from "moment";
//import moment from 'moment';
class GetTc extends React.Component {
  state = {
    users: [],
    isLoading: true,
    errors: null,
  };

  getNotices() {
    axios
      .get("http://mpsckp.com/gettc.php")
      .then((response) =>
        response.data.map((notice) => ({
          name: `${notice.name}`,
          dob: `${notice.dob}`,
          tid: `${notice.tid}`,
          file: `${notice.file}`,
          date: `${notice.date}`,
        }))
      )
      .then((data) => {
        this.setState({
          data,
          isLoading: false,
        });
      })
      .catch((error) => this.setState({ error, isLoading: false }));
  }

  componentDidMount() {
    this.getNotices();
  }
  render() {
    const { isLoading, data } = this.state;
    Moment.locale("en");
    return (
      <React.Fragment>
        <table className="table table-bordered">
          <tr>
            <th>Name</th>
            <th className="text-center">Date of Birth</th>
            <th className="text-center">Download</th>
          </tr>
          {!isLoading ? (
            data.map((notice) => {
              const { name, file,date } = notice;
              return (
                <tr>
                  <td>{name}</td>
                  <td className="text-center">{Moment(date).format("DD")} {Moment(date).format("MMMM")}, {Moment(date).format("YYYY")}</td>
                  <td className="text-center">
                    <a
                      href={`${"http://admin.mpsckp.com/tcs/"}${file}`}
                      rel="noreferrer" target="_blank"
                    >
                      Download
                    </a>
                  </td>
                </tr>
              );
            })
          ) : (
            <p>Loading...</p>
          )}
        </table>
      </React.Fragment>
    );
  }
}

export default GetTc;
