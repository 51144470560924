import React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import School from "../components/Gallery/School";
import "./Gallery.css";

//http://www.mpsckp.com/gallery/school/images/1.jpg
const MpsGallery = () => {
  return (
    <div className="inner-body">
      <Container fluid>
        <Row className="inner">
          <div className="inner-head-outer">
            <div className="inner-head">
              <Col>
                <h2>Gallery</h2>
                <Breadcrumb>
                  <Breadcrumb.Item href="../">
                    <i className="fa fa-home"></i> Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Gallery</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </div>
          </div>
        </Row>
      </Container>
      <div className="mib relative">
        <div className="leftline"></div>

        <div className="bottomline"></div>
        <Router>
          <Switch>
            <Route path="/School">
              <School />
            </Route>
          </Switch>
        </Router>
        <Container>
          <Row>
            <Col>
              <div className="mpsgallery">
                <Link to={"/School"}>
                  <img
                    src="http://www.mpsckp.com/gallery/school/images/1.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails"><Link to={"/School"}>The School</Link></div>
              </div>
            </Col>
            <Col>
              <div className="mpsgallery">
              <Link to={"/RepublicDay"}>
                <img
                  src="http://www.mpsckp.com/gallery/republic_day_20/1.jpg"
                  alt=""
                  className="img-fluid"
                />
                </Link>
                <div className="mpsboxdetails"><Link to={"/RepublicDay"}>Republic Day 2020</Link></div>
              </div>
            </Col>
            <Col>
              <div className="mpsgallery">
              <Link to={"/PrizeDistribution"}>
                <img
                  src="http://www.mpsckp.com/gallery/prize_distribution_20/1.jpg"
                  alt=""
                  className="img-fluid"
                />
                </Link>
                <div className="mpsboxdetails"><Link to={"/PrizeDistribution"}>Prize Distribution</Link></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default MpsGallery;
