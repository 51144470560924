import React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import { Link } from "react-router-dom";
import "../Gallery.css";

//http://www.mpsckp.com/gallery/school/images/1.jpg
const School = () => {
  const numbers = [1, 2, 3, 4, 5];
  // const i;
  const listItems = numbers.map((number, index) => {
    if (index % 3 === 0)
      return (
        <Row>
          <Col>
            <div className="mbox">
              <Item
                original={`${"http://www.mpsckp.com/gallery/school/images/"}${number}${".jpg"}`}
                thumbnail={`${"http://www.mpsckp.com/gallery/school/images/"}${number}${".jpg"}`}
                width="1024"
                height="768"
                className="img-fluid"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={`${"http://www.mpsckp.com/gallery/school/images/"}${number}${".jpg"}`}
                    className="img-fluid"
                    alt=""
                  />
                )}
              </Item>
            </div>
          </Col>
          <Col>
            <div className="mbox">
              <Item
                original={`${"http://www.mpsckp.com/gallery/school/images/"}${
                  number + 1
                }${".jpg"}`}
                thumbnail={`${"http://www.mpsckp.com/gallery/school/images/"}${
                  number + 1
                }${".jpg"}`}
                width="1024"
                height="768"
                className="img-fluid"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={`${"http://www.mpsckp.com/gallery/school/images/"}${
                      number + 1
                    }${".jpg"}`}
                    className="img-fluid"
                    alt=""
                  />
                )}
              </Item>
            </div>
          </Col>
          <Col>
            <div className="mbox">
              <Item
                original={`${"http://www.mpsckp.com/gallery/school/images/"}${number+2}${".jpg"}`}
                thumbnail={`${"http://www.mpsckp.com/gallery/school/images/"}${number+2}${".jpg"}`}
                width="1024"
                height="768"
                className="img-fluid"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={`${"http://www.mpsckp.com/gallery/school/images/"}${number+2}${".jpg"}`}
                    className="img-fluid"
                    alt=""
                  />
                )}
              </Item>
            </div>
          </Col>
        </Row>
      );
    else {
      return "";
    }
  });

  return (
    <div className="inner-body">
      <Container fluid>
        <Row className="inner">
          <div className="inner-head-outer">
            <div className="inner-head">
              <Col>
                <h2>The School</h2>
                <Breadcrumb>
                  <Breadcrumb.Item href="../">
                    <i className="fa fa-home"></i> Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={"/Gallery"}>
                      <i className="fa fa-home"></i> Gallery
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>The School</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </div>
          </div>
        </Row>
      </Container>
      <div className="mib relative">
        <div className="leftline"></div>

        <div className="bottomline"></div>
        <Container>
          <Gallery>{listItems}</Gallery>
        </Container>
      </div>
    </div>
  );
};

export default School;
