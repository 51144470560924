import React from "react";
import { Carousel, Container, Row, Col } from "react-bootstrap";
import GetNotice from "./GetNotice";
import Iframe from "react-iframe";
import "./Home.css";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Link
} from "react-router-dom";
import slide1 from "../images/slide1.jpg";
import slide2 from "../images/slide2.jpg";
import slide3 from "../images/slide3.jpg";
import slide4 from "../images/slide4.jpg";
import slide5 from "../images/slide5.jpg";
import slide6 from "../images/slide6.jpg";
import slide7 from "../images/slide7.jpg";
class Home extends React.Component {
  // const Home = () => {
  // constructor(props){
  //   super(props);
  // }
  componentDidMount() {
    AOS.init({
      duration: 2000,
    });
  }
  render() {
    return (
      <div>
        <Container fluid data-aos="fade-up">
          <Row>
            <Carousel fade>
              <Carousel.Item>
                <img className="d-block w-100" src={slide1} alt="First slide" />
                <Carousel.Caption>
                  <h3> </h3>
                  <p></p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={slide2}
                  alt="Second slide"
                />

                <Carousel.Caption>
                  <h3> </h3>
                  <p></p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={slide3} alt="Third slide" />

                <Carousel.Caption>
                  <h3> </h3>
                  <p></p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={slide4}
                  alt="Fourth slide"
                />

                <Carousel.Caption>
                  <h3> </h3>
                  <p></p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={slide5} alt="Fifth slide" />

                <Carousel.Caption>
                  <h3> </h3>
                  <p></p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={slide6} alt="Fifth slide" />

                <Carousel.Caption>
                  <h3> </h3>
                  <p></p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={slide7} alt="Fifth slide" />

                <Carousel.Caption>
                  <h3> </h3>
                  <p></p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Row>
          <Row className="boxarea">
            <Col md={4}>
              <div className="notice">
                <h3>
                  <i className="fa fa-clock-o"></i> Notice
                </h3>
                <div className="noticedata">
                  <GetNotice />
                </div>
              </div>
            </Col>
            <Col md={8}>
              <Row>
                <Col md={4}>
                <Link to={"/about"}>
                    <div className="box">
                      <i
                        className="fa fa-sticky-note fa-3x"
                        aria-hidden="true"
                      ></i>
                      <h4>Mission</h4>
                      <h5>Statement</h5>
                    </div>
                  </Link>
                </Col>
                <Col md={4}>
                  <a
                    href="http://www.mpsckp.com/pdf/annual_report_2019.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="box">
                      <i className="fa fa-leanpub fa-3x" aria-hidden="true"></i>
                      <h4>Annual</h4>
                      <h5>Report</h5>
                    </div>
                  </a>
                </Col>
                <Col md={4}>
                  <a
                    href="http://www.mpsckp.com/pdf/oasis_2020.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="box">
                      <i className="fa fa-book fa-3x" aria-hidden="true"></i>
                      <h4>OASIS</h4>
                      <h5>Final Report</h5>
                    </div>
                  </a>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                <Link to={"/Tc"}>
                    <div className="box">
                      <i
                        className="fa fa-file-pdf-o fa-3x"
                        aria-hidden="true"
                      ></i>
                      <h4>TC / SLC</h4>
                      <h5>Download</h5>
                    </div>
                  </Link>
                </Col>
                <Col md={4}>
                <Link to={"/SchoolInformation"}>
                    <div className="box">
                      <i
                        className="fa fa-building fa-3x"
                        aria-hidden="true"
                      ></i>
                      <h4>School</h4>
                      <h5>Information</h5>
                    </div>
                  </Link>
                </Col>
                <Col md={4}>
                <Link to={"/StudentArena"}>
                    <div className="box">
                      <i className="fa fa-users fa-3x" aria-hidden="true"></i>
                      <h4>Student</h4>
                      <h5>Arena</h5>
                    </div>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="about" data-aos="fade-up">
            <Col>
              <Row>
                <Col>
                  <h3>
                    <i className="fa fa-building" aria-hidden="true"></i> The
                    School
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Iframe
                    url="http://www.youtube.com/embed/mztJbcr-ZPw"
                    width="100%"
                    height="450px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative"
                  />
                </Col>
                <Col md={6}>
                  <p>
                    <b>Madhusudan Public School (MPS)</b> has been started from
                    1<sup>st</sup> April 2009 to cater quality English education
                    in order to meet the needs of the fast changing 21
                    <sup>st</sup>&nbsp; century world.
                  </p>
                  <p>
                    The school is affiliated to <b>CBSE, New Delhi</b>. The
                    Affiliation No. is <b>3430268</b> and School No. is{" "}
                    <b>58037</b>.
                  </p>
                  <p>
                    <b>Madhusudan Public School</b> popularly known as{" "}
                    <b>MPS</b>&nbsp; which has come a long way in nurturing
                    young minds since its inception. Its impressive jouney
                    started in 2009 with the ambience for a ground breaking
                    education system. The school&nbsp; provides various
                    facilities like&nbsp;
                    <strong>
                      VAST PLAYGROUND, DIGITAL CLASS, COMPUTER LAB, SCIENCE LAB
                    </strong>
                    &nbsp; and other separrate <strong>LAB</strong> for various
                    subjects, spacious and well staked <strong>LIBRARY</strong>.
                    School follows 100 percent child-centered approach with
                    emphasis on Continuous &amp; Comprehensive Evaluation system
                    implemented by C.B.S.E. Participation in the co-scholastic
                    activities has range from&nbsp;
                    <strong>YOGA, KARATE, ART &amp; CARFT, MUSIC, DANCE</strong>
                    &nbsp; etc. are an integral part of the learning system.
                  </p>
                  <p>
                    The school is a co-educational English medium and strictly
                    follows&nbsp;
                    <strong>CCE guidelines provided by CBSE, New Delhi</strong>.
                    Our School holds a motto -
                    <b>" MOTIVATE PERSONAL STRENGTH "</b> to provide the
                    students all sorts of opportunities for achieving all-round
                    and harmonious development.
                  </p>
                  <p>
                    <b>MPS</b> is situated at Asantalia 4 kms away from&nbsp;
                    <strong>Railway Station Chakradharpur</strong> in west
                    direction linked with&nbsp;
                    <strong>Chakradharpur - Ranchi Main Road NH-75 (E)</strong>.
                    It is nearly 5 minutes drive to reach <strong>MPS</strong>
                    &nbsp; which is nearby&nbsp;
                    <strong>
                      SDO Office, Porahat, Chakradharpur, W. Singhbhum,
                      Jharkhand
                    </strong>
                    .
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mps">
            <Col md={4}>
              <a href="https://www.mpsckp.com/pdf/oasis_2020.pdf">
                <div className="mpsbox">
                  <img
                    src="https://www.mpsckp.com/gallery/school/images/8.jpg"
                    alt="Motivate"
                    className="img-fluid"
                  />
                  <div className="mpsboxtxt">
                    <h3>Motivate</h3>
                  </div>
                </div>
              </a>
            </Col>
            <Col md={4}>
              <div className="mpsbox">
                <img
                  src="https://www.mpsckp.com/gallery/school/images/8.jpg"
                  alt="Motivate"
                  className="img-fluid"
                />
                <div className="mpsboxtxt">
                  <h3>Personal</h3>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsbox">
                <img
                  src="https://www.mpsckp.com/gallery/school/images/8.jpg"
                  alt="Motivate"
                  className="img-fluid"
                />
                <div className="mpsboxtxt">
                  <h3>Strength</h3>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="cca" data-aos="fade-up">
            <div className="cca_transparent">
              <Col>
                <Row>
                  <Col>
                    <h3>Our Gallery</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mpsgallery">
                      <Link to={"/School"}>
                        <img
                          src="http://www.mpsckp.com/gallery/school/images/1.jpg"
                          alt=""
                          className="img-fluid"
                        />
                      </Link>
                      <div className="mpsboxdetails">
                        <Link to={"/School"}>The School</Link>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="mpsgallery">
                      <Link to={"/RepublicDay"}>
                        <img
                          src="http://www.mpsckp.com/gallery/republic_day_20/1.jpg"
                          alt=""
                          className="img-fluid"
                        />
                      </Link>
                      <div className="mpsboxdetails">
                        <Link to={"/RepublicDay"}>Republic Day 2020</Link>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="mpsgallery">
                      <Link to={"/PrizeDistribution"}>
                        <img
                          src="http://www.mpsckp.com/gallery/prize_distribution_20/1.jpg"
                          alt=""
                          className="img-fluid"
                        />
                      </Link>
                      <div className="mpsboxdetails">
                        <Link to={"/PrizeDistribution"}>
                          Prize Distribution
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </div>
          </Row>
          {/* <Row className="toppers">
            <Col>
              <Row>
                <Col>
                  <h3>Our Topper</h3>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Carousel fade>
                    <Carousel.Item>
                      <Row>
                        <Col md={2}>
                          <div className="topperbox">
                            <img
                              src="http://www.mpsckp.com/images/2k19_toppers/1.jpg"
                              alt="Motivate"
                              className="img-fluid"
                            />
                            <div className="per">11.11%</div>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="topperbox">
                            <img
                              src="http://www.mpsckp.com/images/2k19_toppers/2.jpg"
                              alt="Motivate"
                              className="img-fluid"
                            />
                            <div className="per">11.11%</div>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="topperbox">
                            <img
                              src="http://www.mpsckp.com/images/2k19_toppers/3.jpg"
                              alt="Motivate"
                              className="img-fluid"
                            />
                            <div className="per">11.11%</div>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="topperbox">
                            <img
                              src="http://www.mpsckp.com/images/2k19_toppers/4.jpg"
                              alt="Motivate"
                              className="img-fluid"
                            />
                            <div className="per">11.11%</div>
                          </div>
                        </Col>

                        <Col md={2}>
                          <div className="topperbox">
                            <img
                              src="http://www.mpsckp.com/images/2k19_toppers/5.jpg"
                              alt="Motivate"
                              className="img-fluid"
                            />
                            <div className="per">11.11%</div>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="topperbox">
                            <img
                              src="http://www.mpsckp.com/images/2k19_toppers/5.jpg"
                              alt="Motivate"
                              className="img-fluid"
                            />
                            <div className="per">90.11%</div>
                          </div>
                        </Col>
                      </Row>
                    </Carousel.Item>
                  </Carousel>
                </Col>
              </Row>
            </Col>
          </Row> */}
        </Container>
      </div>
    );
  }
}

export default Home;
