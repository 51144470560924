import React from "react";
import axios from "axios";
import Moment from "moment";
import { Container, Row, Col,Breadcrumb } from "react-bootstrap";
import parse from 'html-react-parser';
 
//.get("http://mpsckp.com/noticedata.php?id="+this.props.match.params.id)
class GetSingleNotice extends React.Component {
    state = {
      users: [],
      isLoading: true,
      errors: null,
    };
  
    getNotices() {
      axios
        .get("http://mpsckp.com/noticedatasingle.php?id="+this.props.match.params.id)
        .then((response) =>
          response.data.map((notices) => ({
            notice: `${notices.notice}`,
            title: `${notices.title}`,
            date: `${notices.date}`,
            id: `${notices.id}`,
          }))
        )
        .then((data) => {
          this.setState({
            data,
            isLoading: false,
          });
        })
        .catch((error) => this.setState({ error, isLoading: false }));
    }
  
    componentDidMount() {
      this.getNotices();
    }
    
    render() {
      const { isLoading, data } = this.state;
      Moment.locale("en");
      return (
        <React.Fragment>
          <div>
            {!isLoading ? (
              data.map((notices) => {
                const { title, date, notice } = notices;
                return (
                    <div className="inner-body">
      <Container fluid>
        <Row className="inner">
          <div className="inner-head">
            <Col>
              <h2>Notice</h2>
              <Breadcrumb>
                <Breadcrumb.Item href="../">
                  <i className="fa fa-home"></i> Home
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Notice</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </div>
        </Row>
      </Container>
                  <Container>
                    <Row className="noticedetails" key={title}>
                      <Col md="auto">
                        <p className="calendar">
                          {Moment(date).format("DD")}
                          <em>{Moment(date).format("MMMM")}</em>
                        </p>
                      </Col>
                      <Col>
                        <h2>
                           {title}
                        </h2>
                        <div>{parse(notice)}</div>
                      </Col>
                      <hr />
                    </Row>
                  </Container>
                  </div>
                );
              })
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </React.Fragment>
      );
    }
  }
 
export default GetSingleNotice;
